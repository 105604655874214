<template>
<div>
    <br>
    <table width="100%" border="0" cellspacing="0" cellpadding="0">
		<tr> 
			<td>&nbsp;</td>
			<td width="974" height="25">&nbsp;</td>
			<td>&nbsp;</td>
		</tr>
		<tr bgcolor="F4F4F4"> 
			<td>&nbsp;</td>
			<td width="974"> 
			<table width="974" border="0" cellspacing="0" cellpadding="0" height="65" align="right">
				<tr> 
				<td><img src="@/assets/images/copy_logo.png"></td>
				<td class="copy">
				(주)나이스디앤비&nbsp;|&nbsp;사업자등록번호 107-86-24874<br>
<<<<<<< .mine
				(04535) 서울특별시 중구 소공로 70 포스트타워 B동, 14층 &nbsp;|&nbsp;TEL 02.2122.2508&nbsp;|&nbsp;FAX 02.2122.2592<br>
||||||| .r16375
				(04117)서울 마포구 마포대로 217 (아현동) 크레디트센터&nbsp;|&nbsp;TEL 02.2122.2508&nbsp;|&nbsp;FAX 02.2122.2592<br>
=======
				(04535) 서울특별시 중구 소공로 70 포스트타워 B동, 14층&nbsp;|&nbsp;TEL 02.2122.2508&nbsp;|&nbsp;FAX 02.2122.2592<br>
>>>>>>> .r16976
				COPYRIGHT&copy; 2008 NICE D&amp;B CO.,LTD. ALL RIGHTS RESERVED
				</td>
				<td><a href="javascript:;" @click="popupOpen()" style="font-weight:bold;">개인정보처리방침</a></td>
				</tr>
			</table>
			</td>
			<td>&nbsp;</td>
		</tr>
    </table>
</div>
</template>

<script>

export default {
  	methods: {
        popupOpen(sMenu) {
            window.open('https://www.nicednb.com/etc_pop/privacy_pop2015.html', 'privacy_pop', 'height=700px, width=610px');
        }
	},
};
</script>
